import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'; // Importa los iconos de ordenación
import instance from '../../../api/api';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import SearchBar from '../../../components/SearchBar';
import { formatFecha } from '../../../util/util';

const ContactList = () => {
    const { page } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const [contacts, setContacts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchField, setSearchField] = useState('name');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    const filterList = [{ key: 'name', value: 'Nombre' }, { key: 'lastName', value: 'Apellido' }, { key: 'age', value: 'Edad' }, { key: 'email', value: 'Email' }, { key: 'number', value: 'Número de telefono' },]

    useEffect(() => {
        setCurrentPage(parseInt(page) || 1);
    }, [page]);

    useEffect(() => {
        fetchContacts(currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage);
    }, [currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage]);

    const fetchContacts = async (page, searchField, searchQuery, sortField, sortOrder, limit) => {
        setLoading(true);
        try {
            const response = await instance.get(`/contacts`, {
                params: {
                    page,
                    limit,
                    field: searchField,
                    search: searchQuery,
                    sortField,
                    sortOrder
                }
            });
            setContacts(response.data.contacts);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error capturar contactos", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        navigate(`/contacts/${page}`);
    };

    const handleSearch = (field, query) => {
        setSearchField(field);
        setSearchQuery(query);
        setCurrentPage(1);
        navigate(`/contacts/1`);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
        navigate(`/contacts/1`);
    };

    const renderSortIcon = (field) => {
        if (sortField !== field) return <FaSort />;
        if (sortOrder === 'asc') return <FaSortUp />;
        return <FaSortDown />;
    };

    return (
        <div className="container">
            <h1 className="my-3">Contactos</h1>
            <SearchBar onSearch={handleSearch} filterList={filterList} />
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => handleSort('name')}>Nombre {renderSortIcon('name')}</th>
                                    <th scope="col" onClick={() => handleSort('lastName')}>Apellido {renderSortIcon('lastName')}</th>
                                    <th scope="col" onClick={() => handleSort('email')}>Email {renderSortIcon('email')}</th>
                                    <th scope="col" onClick={() => handleSort('number')}>Número de telefono {renderSortIcon('number')}</th>
                                    <th scope="col" onClick={() => handleSort('gender')}>Género {renderSortIcon('gender')}</th>
                                    <th scope="col" onClick={() => handleSort('age')}>Edad {renderSortIcon('age')}</th>
                                    <th scope="col" onClick={() => handleSort('lastDate')}>Última Cita {renderSortIcon('lastDate')}</th>
                                    <th scope="col" onClick={() => handleSort('lastDateEmailSend')}>Último Email Enviado {renderSortIcon('lastDateEmailSend')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts && contacts.map((contact, index) => (
                                    <tr key={contact._id}>
                                        <th scope="row">{index + 1 + (currentPage - 1) * itemsPerPage}</th>
                                        <td>{contact.name}</td>
                                        <td>{contact.lastName}</td>
                                        <td>{contact.email?.toLowerCase()}</td>
                                        <td>{contact.number}</td>
                                        <td>{contact.gender}</td>
                                        <td>{contact.age}</td>
                                        <td>{contact.lastDate ? formatFecha(contact.lastDate)  : 'N/A'}</td>
                                        <td>{contact.lastDateEmailSend ? formatFecha(contact.lastDateEmailSend) : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </>
            )}
        </div>
    );
};

export default ContactList;
